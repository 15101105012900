import { Injectable } from '@angular/core';
import { BrandingService } from 'tt-branding-lib';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  config: any;

  constructor(private branding: BrandingService) {
    this.branding.config$.subscribe((data: any) => (this.config = data));
  }

  getPath(path: string) {
    console.log(path);
    console.log(this.config);
    return this.config?.app?.path + path;
  }
}
