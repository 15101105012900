import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BrandingService } from 'tt-branding-lib';
import { BaseService } from './base.service';

const FIREBASE_STORE_PATH = '/jobs';

@Injectable({
  providedIn: 'root',
})
export class JobService extends BaseService {
  constructor(private store: AngularFirestore, branding: BrandingService) {
    super(branding);
  }
  delete(data: any) {
    let basePath = this.getPath(FIREBASE_STORE_PATH);
    return this.store.collection(basePath).doc(data.id).delete();
  }

  update(id: string, data: any) {
    let basePath = this.getPath(FIREBASE_STORE_PATH);
    return this.store.collection(basePath).doc(id).set(data, { merge: true });
  }

  listById(id: string) {
    let basePath = this.getPath(FIREBASE_STORE_PATH);
    return this.store.collection(basePath).doc(id).ref.get();
  }

  add(data: any) {
    let basePath = this.getPath(FIREBASE_STORE_PATH);
    data['id'] = data.id ? data.id : this.store.createId();
    return this.store.collection(basePath).doc(data.id).set(data);
  }
  listJobsLimit10() {
    let basePath = this.getPath(FIREBASE_STORE_PATH);
    return this.store
      .collection(basePath, (ref) => ref.orderBy('createdDate').limitToLast(10))
      .valueChanges();
  }
}
