<tt-breadcrumb
  title="Jobs Listing"
  [items]="[{ label: 'Jobs' }]"
></tt-breadcrumb>

<tt-data-table
  *ngIf="jobs.length > 0; else nopendingjobs"
  [data]="jobs"
  [config]="config"
  (viewAction)="viewAction($event)"
  (editAction)="editAction($event)"
></tt-data-table>

<ng-template #nopendingjobs>
  <div class="container">
    <div class="p-3">
      <mat-toolbar color="accent">
        <div>No Jobs for Review</div>
      </mat-toolbar>
      <mat-card style="min-height: 300px !important" class="text-center">
        <div class="d-flex flex-column">
          <h2>Your have no Jobs posting pending for review!</h2>
          <div class="text-center">
            <mat-icon
              style="font-size: 100px !important; height: auto; width: auto"
            >
              grid_off
            </mat-icon>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</ng-template>
