import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdvertisementService } from 'src/app/services/advertisement.service';
import { BrandingService } from 'tt-branding-lib';

@Component({
  selector: 'app-advertisement-list',
  templateUrl: './advertisement-list.component.html',
  styleUrls: ['./advertisement-list.component.scss'],
})
export class AdvertisementListComponent implements OnInit {
  adsList: any[] = [];
  config: any;

  constructor(
    private adsService: AdvertisementService,
    private branding: BrandingService,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.branding
      .loadAppJson('table/ads')
      .subscribe((config) => (this.config = config));
    this.adsService.listWithLimit10().subscribe((adsList) => {
      this.adsList = adsList;
      console.log(adsList);
    });
  }

  viewAction(rowData: any) {
    this.router.navigateByUrl('/ads/' + rowData?.id + '/view');
  }

  aditAction(rowData: any) {
    this.router.navigateByUrl('/ads/' + rowData?.id + '/edit');
  }
}
