import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-employers-edit',
  templateUrl: './employers-edit.component.html',
  styleUrls: ['./employers-edit.component.scss']
})
export class EmployersEditComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
