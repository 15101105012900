import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { BrandingService } from 'tt-branding-lib';

@Component({
  selector: 'app-employers-list',
  templateUrl: './employers-list.component.html',
  styleUrls: ['./employers-list.component.scss'],
})
export class EmployersListComponent implements OnInit {
  employers: any[] = [];
  config: any;

  constructor(
    private userService: UserService,
    private branding: BrandingService,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.branding
      .loadAppJson('table/employers')
      .subscribe((config) => (this.config = config));
    this.userService.listEmployersLimit10().subscribe((employers) => {
      this.employers = employers;
    });
  }
  viewAction(rowData: any) {
    this.router.navigateByUrl('/users/employers/' + rowData?.id + '/view');
  }
  editAction(rowData: any) {
    this.router.navigateByUrl('/users/employers/' + rowData?.id + '/edit');
  }
}
