import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { JobsRoutingModule } from './jobs-routing.module';
import { JobReviewComponent } from './job-review/job-review.component';
import { JobViewComponent } from './job-view/job-view.component';
import { JobEditComponent } from './job-edit/job-edit.component';
import { JobDashboardComponent } from './job-dashboard/job-dashboard.component';
import { JobListingComponent } from './job-listing/job-listing.component';
import { TtCommonLibModule } from 'tt-common-lib';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  declarations: [
    JobReviewComponent,
    JobViewComponent,
    JobEditComponent,
    JobDashboardComponent,
    JobListingComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    JobsRoutingModule,
    TtCommonLibModule,
    MatIconModule,
    MatToolbarModule,
    MatCardModule,
  ],
})
export class JobsModule {}
