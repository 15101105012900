import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdvertisementDashboardComponent } from './advertisement-dashboard/advertisement-dashboard.component';
import { AdvertisementEditComponent } from './advertisement-edit/advertisement-edit.component';
import { AdvertisementListComponent } from './advertisement-list/advertisement-list.component';
import { AdvertisementViewComponent } from './advertisement-view/advertisement-view.component';

const routes: Routes = [
  { path: '', component: AdvertisementListComponent },
  { path: 'dashboard', component: AdvertisementDashboardComponent },
  { path: 'list', component: AdvertisementListComponent },
  { path: ':id/view', component: AdvertisementViewComponent },
  { path: ':id/edit', component: AdvertisementEditComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdvertisementsRoutingModule {}
