import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsersRoutingModule } from './users-routing.module';
import { LeadManagmentComponent } from './lead-managment/lead-managment.component';
import { JobsSeekersListComponent } from './jobs-seekers-list/jobs-seekers-list.component';
import { JobsSeekersViewComponent } from './jobs-seekers-view/jobs-seekers-view.component';
import { JobsSeekersEditComponent } from './jobs-seekers-edit/jobs-seekers-edit.component';
import { EmployersListComponent } from './employers-list/employers-list.component';
import { EmployersViewComponent } from './employers-view/employers-view.component';
import { EmployersEditComponent } from './employers-edit/employers-edit.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { TtCommonLibModule } from 'tt-common-lib';

@NgModule({
  declarations: [
    LeadManagmentComponent,
    JobsSeekersListComponent,
    JobsSeekersViewComponent,
    JobsSeekersEditComponent,
    EmployersListComponent,
    EmployersViewComponent,
    EmployersEditComponent,
  ],
  imports: [
    CommonModule,
    UsersRoutingModule,
    MatToolbarModule,
    MatCardModule,
    MatIconModule,
    TtCommonLibModule,
  ],
})
export class UsersModule {}
