import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { BrandingService } from 'tt-branding-lib';

@Component({
  selector: 'app-lead-managment',
  templateUrl: './lead-managment.component.html',
  styleUrls: ['./lead-managment.component.scss'],
})
export class LeadManagmentComponent implements OnInit {
  leads: any[] = [];
  config: any;

  constructor(
    private userService: UserService,
    private branding: BrandingService
  ) {}
  ngOnInit(): void {
    this.branding
      .loadAppJson('table/leads')
      .subscribe((config) => (this.config = config));
    this.userService.listLeadsLimit10().subscribe((leads) => {
      this.leads = leads;
    });
  }
}
