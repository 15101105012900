import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  LoginComponent,
  ManageUserComponent,
  ProfileComponent,
  UnauthorizedComponent,
} from 'tt-authentication-lib';
import { UserAuthGuard } from './authentication/guards/user-auth.guard';
import { HomeComponent } from './pages/components/home/home.component';
import {
  CollectionViewComponent,
  DeleteItemComponent,
  EditFormComponent,
  ExportCollectionComponent,
  ImportCollectionComponent,
  NewFormComponent,
  ViewCollectionItemComponent,
  FormDataViewComponent,
} from 'tt-collection-view-lib';
import { DataImporterComponent } from 'tt-web-store';
import { JobsModule } from './pages/jobs/jobs.module';
import { PaymentsModule } from './pages/payments/payments.module';
import { UsersModule } from './pages/users/users.module';
import { AdvertisementsModule } from './pages/advertisements/advertisements.module';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [UserAuthGuard],
  },
  { path: 'jobs', loadChildren: () => JobsModule },
  { path: 'payments', loadChildren: () => PaymentsModule },
  { path: 'users', loadChildren: () => UsersModule },
  { path: 'ads', loadChildren: () => AdvertisementsModule },

  {
    path: 'home',
    component: HomeComponent,
    canActivate: [UserAuthGuard],
  },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'login', component: LoginComponent },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [UserAuthGuard],
  },
  {
    path: 'user/manage',
    component: ManageUserComponent,
    canActivate: [UserAuthGuard],
  },
  {
    path: 'forms/list',
    component: FormDataViewComponent,
    canActivate: [UserAuthGuard],
  },
  {
    path: 'manage/:id',
    component: CollectionViewComponent,
    canActivate: [UserAuthGuard],
  },
  {
    path: 'manage/:id/new',
    component: NewFormComponent,
    canActivate: [UserAuthGuard],
  },
  {
    path: 'manage/:id/import',
    component: ImportCollectionComponent,
    canActivate: [UserAuthGuard],
  },
  {
    path: 'manage/:id/export',
    component: ExportCollectionComponent,
    canActivate: [UserAuthGuard],
  },
  {
    path: 'manage/:id/view/:itemId',
    component: ViewCollectionItemComponent,
    canActivate: [UserAuthGuard],
  },
  {
    path: 'manage/:id/edit/:itemId',
    component: EditFormComponent,
    canActivate: [UserAuthGuard],
  },
  {
    path: 'manage/:id/delete/:itemId',
    component: DeleteItemComponent,
    canActivate: [UserAuthGuard],
  },
  { path: 'data/manage', component: DataImporterComponent },
  {
    path: '**',
    component: HomeComponent,
    canActivate: [UserAuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
