<tt-breadcrumb
  title="Update Job Information"
  [items]="[{ label: 'Jobs', url: '/jobs/list' }, { label: 'Edit' }]"
></tt-breadcrumb>

<ng-container>
  <tt-custom-single-form
    [formId$]="jobs$"
    user="admin"
    [model$]="model$"
    (submitAction)="submit($event)"
  >
  </tt-custom-single-form>
</ng-container>
