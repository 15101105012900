import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JobService } from 'src/app/services/job.service';
import { BrandingService } from 'tt-branding-lib';

@Component({
  selector: 'app-job-listing',
  templateUrl: './job-listing.component.html',
  styleUrls: ['./job-listing.component.scss'],
})
export class JobListingComponent implements OnInit {
  jobs: any[] = [];
  config: any;

  constructor(
    private jobService: JobService,
    private branding: BrandingService,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.branding
      .loadAppJson('table/jobs')
      .subscribe((config) => (this.config = config));
    this.jobService.listJobsLimit10().subscribe((jobs) => {
      this.jobs = jobs;
    });
  }

  viewAction(rowData: any) {
    this.router.navigateByUrl('/jobs/' + rowData?.id + '/view');
  }

  editAction(rowData: any) {
    this.router.navigateByUrl('/jobs/' + rowData?.id + '/edit');
  }

  deleteAction(rowData: any) {
    this.jobService
      .delete(rowData)
      .then(() => console.log('deleted user successfully'));
  }
}
