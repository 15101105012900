import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PaymentsRoutingModule } from './payments-routing.module';
import { PaymentListComponent } from './payment-list/payment-list.component';
import { PaymentDashboardComponent } from './payment-dashboard/payment-dashboard.component';
import { TtCommonLibModule } from 'tt-common-lib';
import { PaymentViewComponent } from './payment-view/payment-view.component';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { SaleOrderComponent } from './sale-order/sale-order.component';
import { SaleViewComponent } from './sale-view/sale-view.component';
import { SubscriptionViewComponent } from './subscription-view/subscription-view.component';
import { SubscriptionListComponent } from './subscription-list/subscription-list.component';
import { SubscriptionEditComponent } from './subscription-edit/subscription-edit.component';

@NgModule({
  declarations: [
    PaymentListComponent,
    PaymentDashboardComponent,
    PaymentViewComponent,
    SaleOrderComponent,
    SaleViewComponent,
    SubscriptionViewComponent,
    SubscriptionListComponent,
    SubscriptionEditComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    TtCommonLibModule,
    PaymentsRoutingModule,
  ],
})
export class PaymentsModule {}
