import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PaymentService } from 'src/app/services/payment.service';
import { BrandingService } from 'tt-branding-lib';

@Component({
  selector: 'app-payment-list',
  templateUrl: './payment-list.component.html',
  styleUrls: ['./payment-list.component.scss'],
})
export class PaymentListComponent implements OnInit {
  payments: any[] = [];
  config: any;

  constructor(
    private paymentService: PaymentService,
    private branding: BrandingService,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.branding
      .loadAppJson('table/payments')
      .subscribe((config) => (this.config = config));
    this.paymentService.listPaymentsLimit10().subscribe((payments) => {
      this.payments = payments;
    });
  }

  viewAction(rowData: any) {
    this.router.navigateByUrl('/payments/' + rowData?.id + '/view');
  }
}
