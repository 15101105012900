import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PaymentDashboardComponent } from './payment-dashboard/payment-dashboard.component';
import { PaymentListComponent } from './payment-list/payment-list.component';
import { PaymentViewComponent } from './payment-view/payment-view.component';
import { SaleOrderComponent } from './sale-order/sale-order.component';
import { SaleViewComponent } from './sale-view/sale-view.component';
import { SubscriptionEditComponent } from './subscription-edit/subscription-edit.component';
import { SubscriptionListComponent } from './subscription-list/subscription-list.component';
import { SubscriptionViewComponent } from './subscription-view/subscription-view.component';

const routes: Routes = [
  { path: '', component: PaymentListComponent },
  { path: 'sale', component: SaleOrderComponent },
  { path: 'sale/:id/view', component: SaleViewComponent },
  { path: 'subscription/list', component: SubscriptionListComponent },
  { path: 'subscription/:id/view', component: SubscriptionViewComponent },
  { path: 'subscription/:id/edit', component: SubscriptionEditComponent },
  { path: 'list', component: PaymentListComponent },
  { path: ':id/view', component: PaymentViewComponent },
  { path: 'dashboard', component: PaymentDashboardComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PaymentsRoutingModule {}
