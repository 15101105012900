import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdvertisementsRoutingModule } from './advertisements-routing.module';
import { AdvertisementListComponent } from './advertisement-list/advertisement-list.component';
import { AdvertisementEditComponent } from './advertisement-edit/advertisement-edit.component';
import { AdvertisementViewComponent } from './advertisement-view/advertisement-view.component';
import { AdvertisementDashboardComponent } from './advertisement-dashboard/advertisement-dashboard.component';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { TtCommonLibModule } from 'tt-common-lib';

@NgModule({
  declarations: [
    AdvertisementListComponent,
    AdvertisementEditComponent,
    AdvertisementViewComponent,
    AdvertisementDashboardComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    TtCommonLibModule,
    AdvertisementsRoutingModule,
  ],
})
export class AdvertisementsModule {}
