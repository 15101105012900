import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { JobService } from 'src/app/services/job.service';
import { CollectionService } from 'tt-collection-view-lib';

@Component({
  selector: 'app-job-edit',
  templateUrl: './job-edit.component.html',
  styleUrls: ['./job-edit.component.scss'],
})
export class JobEditComponent implements OnInit {
  jobs$ = of('jobs');
  formConfig$ = of();

  _itemId: BehaviorSubject<string> = new BehaviorSubject('');
  itemId$ = this._itemId.asObservable();

  _model: BehaviorSubject<any> = new BehaviorSubject({});
  model$ = this._model.asObservable();

  routerSub: Subscription;
  constructor(
    private jobsService: JobService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.routerSub = this.router.events.subscribe(async (event: Event) => {
      if (event instanceof NavigationEnd) {
        let currentRoute = this.route;
        let itemId = currentRoute?.snapshot?.paramMap.get('id');
        itemId && this._itemId.next(itemId);
      }
    });
  }

  submit(data: any) {
    console.log(data);
  }

  ngOnInit(): void {
    this.itemId$.subscribe((itemId: any) => {
      this.jobsService.listById(itemId).then((itemData) => {
        itemData.exists && this._model.next(itemData.data());
      });
    });
  }
}
