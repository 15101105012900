import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { JobDashboardComponent } from './job-dashboard/job-dashboard.component';
import { JobEditComponent } from './job-edit/job-edit.component';
import { JobListingComponent } from './job-listing/job-listing.component';
import { JobReviewComponent } from './job-review/job-review.component';
import { JobViewComponent } from './job-view/job-view.component';

const routes: Routes = [
  {
    path: '',
    component: JobDashboardComponent,
  },
  {
    path: 'list',
    component: JobListingComponent,
  },
  {
    path: 'dashboard',
    component: JobDashboardComponent,
  },
  {
    path: ':id/review',
    component: JobReviewComponent,
  },
  {
    path: ':id/edit',
    component: JobEditComponent,
  },
  {
    path: ':id/view',
    component: JobViewComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class JobsRoutingModule {}
