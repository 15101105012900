import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmployersEditComponent } from './employers-edit/employers-edit.component';
import { EmployersListComponent } from './employers-list/employers-list.component';
import { EmployersViewComponent } from './employers-view/employers-view.component';
import { JobsSeekersEditComponent } from './jobs-seekers-edit/jobs-seekers-edit.component';
import { JobsSeekersListComponent } from './jobs-seekers-list/jobs-seekers-list.component';
import { JobsSeekersViewComponent } from './jobs-seekers-view/jobs-seekers-view.component';
import { LeadManagmentComponent } from './lead-managment/lead-managment.component';

const routes: Routes = [
  { path: 'lead/list', component: LeadManagmentComponent },
  { path: 'jobseekers/list', component: JobsSeekersListComponent },
  { path: 'jobseekers/:id/view', component: JobsSeekersViewComponent },
  { path: 'jobseekers/:id/edit', component: JobsSeekersEditComponent },
  { path: 'employers/list', component: EmployersListComponent },
  { path: 'employers/:id/view', component: EmployersViewComponent },
  { path: 'employers/:id/edit', component: EmployersEditComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UsersRoutingModule {}
