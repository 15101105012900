import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BrandingService } from 'tt-branding-lib';
import { BaseService } from './base.service';

const FIREBASE_STORE_PATH = '/users';
@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  constructor(private store: AngularFirestore, branding: BrandingService) {
    super(branding);
  }

  listJobsSeekersLimit10() {
    let basePath = this.getPath(FIREBASE_STORE_PATH);
    return this.store
      .collection(basePath, (ref) =>
        ref.where('type', '==', 'jobseekers').orderBy('created').limitToLast(10)
      )
      .valueChanges();
  }
  listEmployersLimit10() {
    let basePath = this.getPath(FIREBASE_STORE_PATH);
    return this.store
      .collection(basePath, (ref) =>
        ref.where('type', '==', 'employers').orderBy('created').limitToLast(10)
      )
      .valueChanges();
  }

  listLeadsLimit10() {
    let basePath = this.getPath(FIREBASE_STORE_PATH);
    return this.store
      .collection(basePath, (ref) =>
        ref.where('type', '==', 'lead').orderBy('created').limitToLast(10)
      )
      .valueChanges();
  }
}
