<tt-breadcrumb
  title="Lead Management"
  [items]="[{ label: 'Leads' }]"
></tt-breadcrumb>

<tt-data-table
  *ngIf="leads.length > 0; else nopendingleads"
  [data]="leads"
  [config]="config"
  (viewAction)="viewAction($event)"
></tt-data-table>

<ng-template #nopendingleads>
  <div class="container">
    <div class="p-3">
      <mat-toolbar color="accent">
        <div>No Leads</div>
      </mat-toolbar>
      <mat-card style="min-height: 300px !important" class="text-center">
        <div class="d-flex flex-column">
          <h2>Your have no leads to display!</h2>
          <div class="text-center">
            <mat-icon
              style="font-size: 100px !important; height: auto; width: auto"
            >
              grid_off
            </mat-icon>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</ng-template>
