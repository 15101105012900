import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BrandingService } from 'tt-branding-lib';
import { BaseService } from './base.service';
const FIREBASE_STORE_PATH = '/advertisements';

@Injectable({
  providedIn: 'root',
})
export class AdvertisementService extends BaseService {
  constructor(private store: AngularFirestore, branding: BrandingService) {
    super(branding);
  }

  listWithLimit10() {
    let basePath = this.getPath(FIREBASE_STORE_PATH);
    return this.store
      .collection(basePath, (ref) => ref.orderBy('createdDate').limitToLast(10))
      .valueChanges();
  }
}
